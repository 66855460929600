export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SET_ACTIVE_PAIR = "SET_ACTIVE_PAIR";

export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const DELETE_USER_DATA = "DELETE_USER_DATA";

export const SET_SETTINGS = "SET_SETTINGS";
export const SET_NIGHT_MODE = "SET_NIGHT_MODE";
export const SET_PUSH_MESSAGES = "SET_NIGHT_MODE";

export const OPEN_MODAL = "SET_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const ADD_MODAL_CONTENT = "ADD_MODAL_CONTENT";
export const REMOVE_MODAL_CONTENT = "REMOVE_MODAL_CONTENT";

export const SET_LOCALE = "SET_LOCALE";

export const SET_NODES_DATA = "SET_NODES_DATA";
export const UPDATE_NODES_LIST = "UPDATE_NODES_LIST";
export const SET_ACTIVE_NODE = "SET_ACTIVE_NODE";

export const SET_ASSETS = "SET_ASSETS_PARAMS";